import React, { useState } from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';
import { Link } from 'gatsby';
import TimerContext from '../../shared/timer-context';
import CountdownTimer from './Timer/Countdown';
import PropTypes from 'prop-types'
import Cookies from 'universal-cookie';
import { invokePopup } from '../../shared/PopupWindow';

const TimerWrap = styled('div')`
  .not-logged-in {
    padding-top: 35px;
    margin-top: -35px;
  }

  .top-register-button {
    float: right;
    margin-top: -35px;
    max-width: 49%;
  }
  
  @media (max-width: ${breakpoints.md}) {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
    width: 88%;
    .done-timer-not-logged {
      position: absolute;
      top: 35px;
      left: 15px;
    }
    .not-logged-in {
      margin-top: 0;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .not-logged-in {
      display: none;
    }
  }
`;

const TimeSpan = styled('div')`
  display: inline-block;
  vertical-align: middle;
  padding: 5px 10px 5px 0;
  text-align: center;
`;

const SepSpan = styled('span')`
  margin: 0 2px;
`;


const Completionist = () => {
  const contentContainer = document.getElementById('demo-home-popup-content');
  contentContainer.classList.add('waiting');

  const gameLoginClick = (e) => {
    //console.log('Game Login click');
    const currentPopup = document.getElementById('demo-home-popup');
    currentPopup.classList.remove('popup-open');
    invokePopup(e, 'login-popup-window');
  };

  return (
    <React.Fragment>
      <div className="done-timer-not-logged">
        <TimeSpan>
          <span className="text">Masa tamat: </span>
          <span className="figure">0</span>
          <SepSpan>:</SepSpan>
          <span className="figure">00</span>
        </TimeSpan>
      </div>

      <div className="login-register-prompt">
        <div className="orange h3">Ingin main lagi?</div>
        <p>Anda perlu berdaftar dengan Oppa888 untuk bermain slot menerusi gclub-casino.com.</p>
        <div className="bottom-buttons">
          <button onClick={(e) => gameLoginClick(e)}
                  className="button transparent-button-orange">Log masuk</button>
          <Link to="/register/" className="button gradient-button">Daftar</Link>
        </div>
      </div>
    </React.Fragment>

  );
};


const Timer = props => {
  const [ timerState, setTimerState] = useState({
    startTime: props.startTime,
    timeLeft: props.startTime
  });

  const cookies = new Cookies();
  if (!cookies.get('slot_timer_ends')) {
    cookies.set('slot_timer_ends', Date.now() + props.startTime);
  }


  const timerRenderer = (props) => {
    if (props.completed) {
      return <Completionist />;
    } else {
      return (
        <div className="not-logged-in">
          <Link to="/register/" className="top-register-button button gradient-button">
            Daftar untuk Bermain secara Percuma
          </Link>
          <TimeSpan>
            <span className="text">Masa tamat: </span>
            <span className="figure">{props.minutes}</span>
            <SepSpan>:</SepSpan>
            <span className="figure">{props.formatted.seconds}</span>
          </TimeSpan>
        </div>
      );
    }
  };


  const timerTicker = () => {
    const timerEnds = cookies.get('slot_timer_ends');
    let timeLeft = timerEnds - Date.now();
    let timeForState = timeLeft > 0 ? Math.round(timeLeft/1000)*1000 : 0;
    setTimerState({
      startTime: props.startTime,
      timeLeft: timeForState,
    });
  };

  return (
    <TimerWrap className="timer-wrap">
      <TimerContext.Provider value={{timeLeft: timerState.timeLeft, ticker: timerTicker, renderer: timerRenderer}}>
        <CountdownTimer />
      </TimerContext.Provider>
    </TimerWrap>
  )
};
export default Timer;

Timer.propTypes = {
  startTime: PropTypes.number,
};
